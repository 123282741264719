/*
* Mainly scss modules, only imported to `assets/css/main.scss`
*/

/* ---------- scss placeholder --------- */

%tag-hover {
  background: var(--tag-hover);
  transition: background 0.35s ease-in-out;
}

%table-cell {
  padding: 0.4rem 1rem;
  font-size: 95%;
  white-space: nowrap;
}

%link-hover {
  color: #d2603a !important;
  border-bottom: 1px solid #d2603a;
  text-decoration: none;  
}

%link-color {
  color: var(--link-color);  
}

%link-underline {
  border-bottom: 1px solid var(--link-underline-color);
}

%no-bottom-border {
  border-bottom: none;
}

%heading {
  font-weight: 400;
  font-family: 'Lato', 'Microsoft Yahei', sans-serif;
}

%section {
  #post-wrapper & {
    line-height: 1.2;
    margin-bottom: 1rem;
  }
}

%anchor {
  padding-top: 3.5rem;
  margin-top: -2.5rem;
}

/* ---------- scss mixin --------- */

@mixin no-text-decoration {
  text-decoration: none;
}

@mixin sidebar-links($color: rgba(255, 255, 255, 0.5)) {
  color: $color;
  transition: color 0.35s ease-in-out;
  user-select: none;
}

@mixin icon-round($diameter) {
  border: 1px solid;
  border-radius: 50%;
  width: $diameter;
  height: $diameter;
}

@mixin ml-mr($value) {
  margin-left: $value;
  margin-right: $value;
}

@mixin pl-pr($val) {
  padding-left: $val;
  padding-right: $val;
}

@mixin input-placeholder {
  opacity: 0.6;
}

@mixin semi-bold {
  font-weight: 600;
}

@mixin label($font-size: 1rem, $font-weight: 600, $color: var(--label-color)) {
  color: $color;
  font-size: $font-size;
  font-weight: $font-weight;
}

@mixin panel-label {
  @include label(inherit);

  display: block;
  line-height: 1.2;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-top: 0;
  margin-bottom: 0;
  letter-spacing: -0.02em;
}

@mixin align-center {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
